import classNames from "classnames"
import { usePropsObject } from "@/lib/contexts/appContext"
import ArticleCard from "@/common/cards/article/article"
import MemberCard from "@/common/member/article-card-with-image/article-card-with-image"
import Block from "@/components/wrappers/block"
import styles from "./article-teasers.module.css"

export default function ArticleTeasers({ block }) {
  const { attrs } = block
  const { theme } = usePropsObject()

  if (attrs.articles.length === 0) {
    return null
  }

  return (
    <Block
      block={block}
      width="bleed"
      gap={attrs?.blockGap ?? "normal"}
      innerWrapper={true}
      className={classNames(styles.wrapper, "bg-color-" + attrs.bgColor, {
        "text-white": theme?.bgColor === "black"
      })}>
      {(attrs.heading || attrs.category.label) && (
        <h2>
          {attrs.heading}
          {attrs?.showExtraHeading && (
            <a className="term" href={attrs.category.url}>
              {attrs.category.label}
            </a>
          )}
        </h2>
      )}
      {attrs?.text && <p dangerouslySetInnerHTML={{ __html: attrs.text }}></p>}
      <ul>
        {attrs.articles.map((post, index) => {
          if (attrs?.type === "member-article") {
            return (
              <MemberCard
                key={index}
                showImage={attrs?.showImage}
                showTag={attrs?.showTag}
                showExcerpt={attrs?.showExcerpt}
                showDateline={attrs?.showDateline}
                showTags={attrs?.showTags}
                article={post}
              />
            )
          }
          return (
            <li key={index}>
              <ArticleCard
                post={post}
                showImage={attrs?.showImage}
                showTag={attrs?.showTag}
                showExcerpt={attrs?.showExcerpt}
                showDateline={attrs?.showDateline}
              />
            </li>
          )
        })}
      </ul>
    </Block>
  )
}
